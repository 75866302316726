<template>
  <v-combobox
    key="id"
    v-model="selectedItem"
    :items="formInputList"
    item-text="formName"
    outlined
    label="Select Form"
    clearable
    dense
    @change="selectForm(selectedItem)"
  >
  </v-combobox>
</template>

<script>
import formService from '@/services/FormService'
import useFormService from '@/store/useFormService'

import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'

export default {
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const FORM_APP = 'app-form'

    // Register module
    if (!store.hasModule(FORM_APP)) {
      store.registerModule(FORM_APP, formService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FORM_APP)) store.unregisterModule(FORM_APP)
    })

    const { formInputList, fetchForms } = useFormService()
    const selectedItem = ref(props.form.formName)
    const requiredRules = [v => v !== null || 'Select one form.']

    const selectForm = () => {
      emit('form-select', selectedItem.value)
    }

    return {
      requiredRules,

      fetchForms,
      formInputList,
      selectedItem,

      selectForm,
    }
  },
  mounted() {
    this.fetchForms()
  },
}
</script>
