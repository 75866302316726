<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="text-h5">Document Details</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="PatientDocumentForm"
        >
          <div
            v-if="doc == null || doc == undefined"
          >
            <v-file-input
              id="inputFile"
              v-model="doc"
              accept=".pdf"
              label="Select File"
              type="file"
              @change="getBase"
            ></v-file-input>
          </div>
          <div
            v-if="(patientDocumentDTO.document != null && patientDocumentDTO.document != undefined) || doc != null"
          >
          <v-row>
            <v-col
              cols="12"
              lg="6"
            >
              <patient-select
                :patient="patientDocumentDTO.patient"
                :readonly="!isEdit"
                @patient-select="updatePatientId"
              ></patient-select>
            </v-col>
            <v-col
              cols="12"
              lg="6"
            >
              <task-type-select
                :multiple="false"
                :task-type="patientDocumentDTO.category"
                :readonly="!isEdit"
                @task-type-select="updateType"
              ></task-type-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              lg="6"
            >
              <v-text-field
                v-model="patientDocumentDTO.docTitle"
                label="Title"
                hint="Document.pdf"
                outlined
                dense
                :readonly="!isEdit"
                :rules="[required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              lg="6"
            >
              <v-text-field
                v-model="patientDocumentDTO.note"
                label="Note"
                hint="CIWA Form"
                outlined
                dense
                :readonly="!isEdit"
                :rules="[required]"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              lg="6"
            >
              <v-menu
                ref="menuref"
                v-model="menuSD"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="patientDocumentDTO.recorded"
                    :prepend-icon="icons.mdiCalendar"
                    label="Recorded Date"
                    hint="MM/DD/YYYY"
                    :readonly="!isEdit"
                    :rules="[required]"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-if="isEdit"
                  ref="picker"
                  v-model="patientDocumentDTO.recorded"
                  color="primary"
                  @change="save"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              lg="6"
            >
              <v-checkbox
                v-model="patientDocumentDTO.requireDrReview"
                label="Require Practitioner Review"
                :readonly="!isEdit"
              >
              </v-checkbox>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="text-right">
              <v-btn
                color="error darken-1"
                type="reset"
                text
                @click="closeForm()"
              >
                Close
              </v-btn>
              <v-btn
                v-if="isEdit"
                color="primary darken-1"
                text
                @click="submitForm(patientDocumentDTO)"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import store from '@/store'
import { required } from '@core/utils/validation'
import { ref, onUnmounted } from '@vue/composition-api'
import formService from '@/services/FormService'
import useFormList from '@/store/useFormService'
import PatientSelect from '@/components/patient/PatientSelect.vue'
import FormSelect from '@/components/patient/FormSelect.vue'
import TaskTypeSelect from '@/components/task/TaskTypeSelect.vue'
import { mdiClose, mdiCalendar } from '@mdi/js'

export default {
  components: {
    FormSelect,
    PatientSelect,
    TaskTypeSelect,
  },
  model: {
    prop: 'isDocumentFormActive',
    event: 'update:is-task-form-active',
  },
  props: {
    isDocumentFormActive: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    doc: {
      type: String,
      default: null,
    },
    pdfReview:{
      type: Object,
      default: () => ({}),
    },
    patientDocumentDTO: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    console.log('PatientDocument', props.patientDocumentDTO)
    const FORM_APP = 'app-form'

    // Register module
    if (!store.hasModule(FORM_APP)) {
      store.registerModule(FORM_APP, formService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FORM_APP)) store.unregisterModule(FORM_APP)
    })

    const { postPatientPDF, postPDFResponse } = useFormList()

    const patientDocumentForm = ref(null)
    const menuSD = ref(false)
    const menuDD = ref(false)
    const menuref = ref(null)

    const save = d => {
      menuref.value.save(d)
    }

    const refreshParent = () => {
      emit('refetch-data')
    }

    const closeForm = () => {
      emit('update-dialog', 'render')
    }

    const confirmDelete = () => {
      if(props.pdfReview != {}){
        emit('delete-confirmation', props.pdfReview)
        closeForm()
        refreshParent()
      }
      else {
        closeForm()
        refreshParent()
      }
    }

    const getBase64 = async (file) => {
      console.log('file', file)
      return await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    }

    return {
      patientDocumentForm,
      closeForm,
      confirmDelete,
      refreshParent,
      required,
      menuSD,
      menuDD,
      menuref,
      save,
      getBase64,

      postPatientPDF,
      postPDFResponse,

      icons: {
        mdiClose,
        mdiCalendar,
      },
    }
  },
  mounted(){
    console.log('Mounted', this.doc)
    if (this.doc != undefined && this.doc != null) {
      this.patientDocumentDTO.document = this.doc
    }
  },
  methods: {
    validate() {
      if (this.patientDocumentForm.value.validate(this.patientDocumentDTO)) {
        return true
      }

      return false
    },
    getBase(){
      var selectedFile = document.getElementById("inputFile").files;
      this.getBase64(selectedFile[0]).then(response => this.patientDocumentDTO.document = response)
    },
    submitForm() {
      if(this.patientDocumentDTO.document != null && this.patientDocumentDTO.document != undefined) {

        this.patientDocumentDTO.isActive = true
        console.log('submit', this.patientDocumentDTO)
        this.postPatientPDF(this.patientDocumentDTO)

        this.$watch('postPDFResponse', () => {
          if(this.postPDFResponse){
            this.confirmDelete()
          }


        })
      }
    },
    updatePatientId(patientDTO) {
      this.patientDocumentDTO.patientId = patientDTO.id
    },
    updateType(taskType) {
      this.patientDocumentDTO.category = taskType.display
    },
  },
}
</script>
